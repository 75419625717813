<template>
  <v-row>
    <v-col>
      <v-tabs align-with-title>
        <v-tab to="/admin/business/reports/geo/retailers">Retailers</v-tab>
        <v-tab to="/admin/business/reports/geo/artists">Artists</v-tab>
      </v-tabs>
      <router-view  />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  async created () {
  }
}
</script>
